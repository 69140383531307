import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Projects" mdxType="Title" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://katk.dev"
        }}><strong parentName="a">{` Personal portfolio site `}</strong>{` built with Gatsby and deployed on Netlify`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://weather-app-solo-project.netlify.app/"
        }}>{`Simple `}<strong parentName="a">{` weather app `}</strong>{` made with Vue, deployed on Netlify`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://3.10.55.182:8080/whiskies"
        }}>{`Java Spring Boot app deployed with Docker to AWS`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      