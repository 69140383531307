import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi.
    </Text>
    <p>{`I'm Kat - my spirit animal is a magpie.`}<br parentName="p"></br>{`
`}{`I wrote my first line of code when in May 2020 decided to change my career and joined the coding Bootcamp. I work as Quality Engineer at Zoopla.
I use a few great Test Automation tools: Selenium, Playwright, Postman, Rest Assured, BDD Cucumber/Gherkin.`}<br parentName="p"></br>{`
`}{`And the best: `}<strong parentName="p">{`I keep learning!`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      